.header-component {
  height: 84px;
  padding: 0 30px;
  line-height: 84px;
  background: #001529;
  .menu {
    font-size: 28px;
    color: white;
    vertical-align: middle;
    margin-right: 10px;
  }
  .login{
    display: inline-block;
    //align-items: center;
    line-height: 24px;
  }
  .loginText{
    color: #fff;
    margin-left: 12px;
  }
}
.header-component2 {
  background: #1780e2;
  height: 88px;
  line-height: 88px;
  #logo{
    color: #fff;
    font-size: 24px;
    span{
      vertical-align: middle;
    }
  }
}
.mainMenu {
  .black {
    color: #000 !important;
  }
}
.NewsView{
  padding: 0 24px;
  display: inline-block;
  .head-example{
    vertical-align: middle;
  }
  .anticon-bell{
    font-size: 28px;
    color: #fff;
  }
}
.NewsView-content{
  background: #fff;
  box-shadow: 0 6px 16px -8px rgba(0,0,0,.08), 0 9px 28px 0 rgba(0,0,0,.05), 0 12px 48px 16px rgba(0,0,0,.03);
  .tabs{
    .ant-tabs-nav-wrap{
      display: flex;
      flex: auto;
      .ant-tabs-nav-scroll {
        margin: auto;
      }
    }
  }
  .NewsView-list{
    max-height: 400px;
    width: 336px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .item {
      padding-right: 24px;
      padding-left: 24px;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s;
      &:hover{
        background: #e6f7ff;
      }

      .meta {
        width: 100%;
      }
    }



  }
}

.bottomBar {
  height: 46px;
  color: rgba(0,0,0,.65);
  line-height: 46px;
  text-align: center;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
  transition: all 0.3s;
  div {
    display: inline-block;
    width: 50%;
    cursor: pointer;
    transition: all 0.3s;
    user-select: none;

    &:only-child {
      width: 100%;
    }
    &:not(:only-child):last-child {
      border-left: 1px solid #f0f0f0;
    }
  }
}
.HeadSearch{
  padding: 0 14px;
  display: inline-block;
  cursor: pointer;
  .anticon{
    color: #fff;
    font-size: 18px;
  }
  .search{
    display: inline-block;
    width:0px;
    overflow: hidden;
    height: 28px;
    border-bottom: 1px solid #d9d9d9;
    vertical-align: middle;
    margin-top: -12px;
    transition: all .5s;
    .ant-select{
      vertical-align: top;
      .ant-select-selection{
        background: transparent;
        border: 0 none;
        color: #fff;
      }
    }
    &.show{
      width: 210px;
      margin-left: 8px;
    }
  }
}
.fin-hd-menu{
  display: flex;
  justify-content: space-between;
  align-items: center;
  a{
    color: #fff;
    //padding: 0 16px;
    display: flex;
    width: 100px;
    height: 88px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    img{
      width: 26px;
    }
    span{
      line-height: 2em;
    }
    &.on{
      background: #1890ff;
    }
  }
}
