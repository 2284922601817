.homeContent {
  height: 100%;
  background: #f0f2f5;
  padding: 0 30px 30px;
  :global(.ant-card-body) {
    padding: 20px 24px 8px;
  }
  .homeTitle {
    padding: 30px 0;
    line-height: 1em;
    color: #404447;
    font-size: 20px;
    .fr {
      float: right;
      font-size: 14px;
      color: #9e9e9e;
      button {
        margin-left: 20px;
      }
    }
    .icon {
      vertical-align: top;
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("./images/home_title.png") no-repeat;
      margin-right: 5px;
    }
  }
  .dataTop {
    .dataList {
      background: #fff;
      border: 1px solid #ebebeb;
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      .line1 {
        width: 8px;
        background-image: linear-gradient(to bottom, #3ebaf8, #1f8af1);
      }
      .line2 {
        width: 8px;
        background-image: linear-gradient(to bottom, #faa634, #f95b2a);
      }
      .line3 {
        width: 8px;
        background-image: linear-gradient(to bottom, #27ef9f, #1ccab3);
      }
      .content {
        flex: 1;
        .conTop {
          padding: 26px 0 0 18px;
          display: flex;
          justify-content: start;
          align-items: center;
          flex-wrap: wrap;
          .icon1 {
            width: 67px;
            height: 67px;
            background: url("./images/conTop_01.png") no-repeat;
            margin-right: 16px;
          }
          .icon2 {
            width: 67px;
            height: 67px;
            background: url("./images/conTop_01.png") no-repeat;
            margin-right: 16px;
          }
          .icon3 {
            width: 67px;
            height: 67px;
            background: url("./images/conTop_01.png") no-repeat;
            margin-right: 16px;
          }
          .msg {
            color: #333;
            font-size: 16px;
            margin-right: 10px;
          }
          .num {
            color: #0084ff;
            font-size: 30px;
            font-weight: 600;
            margin-right: 5px;
          }
          .unit {
            color: #0084ff;
            font-size: 16px;
          }
        }
        .conBot {
          color: #8d9498;
          font-size: 14px;
          //line-height: 1em;
          padding: 20px 0;
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          span {
            padding: 0 5px;
          }
        }
      }
    }
  }
}
.salesExtra {
  display: inline-block;
}
.transaction {
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  .totalTile {
    height: 80px;
    line-height: 80px;
    padding: 0 18px;
    color: #333;
    font-size: 16px;
    .num {
      font-size: 30px;
      color: #0088ff;
      font-weight: 600;
    }
    .unit {
      font-size: 20px;
      color: #0088ff;
    }
  }
}
.linkBox {
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  height: 174px;
  .linkTitle {
    padding: 30px 30px 0;
    color: #333;
    font-size: 16px;
    line-height: 1em;
  }
  .linkList {
    padding: 20px 20px 16px;
    text-align: center;
    font-size: 16px;
    color: #8d9498;
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 33%;
      padding-bottom: 14px;
      .icon {
        font-size: 34px;
      }
      .title {
        line-height: 1em;
      }
    }
  }
}

.home {
  padding: 30px;
  .homeItem {
    margin-bottom: 60px;
  }
  .title {
    width: 80px;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    color: rgb(83, 187, 179);
  }
  .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .contentItem {
      width: 250px;
      padding: 25px;
      border-radius: 10px;
      margin-right: 20px;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgb(83, 187, 179);
    }
    .dailyContentItem {
      background-color: rgb(125, 200, 85);
    }
    .weeklyContentItem {
      background-color: rgb(53, 142, 215);
    }
    .monthlyContentItem {
      background-color: rgb(131, 140, 199);
    }
  }
  .dailyTitle {
    color: rgb(125, 200, 85);
  }
  .weeklyTitle {
    color: rgb(53, 142, 215);
  }
  .monthlyTitle {
    color: rgb(131, 140, 199);
  }
}
