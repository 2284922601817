.mt-20 {
  margin-top:20px;
}
.title-l4{
  font-weight: 600;
  font-size: 20px;
}
.title-money{
  color: #f5222d;
  font-weight: 600;
  margin: 0;
  font-size: 16px;
}
.product-avatar-uploader{
  .ant-upload.ant-upload-select-picture-card{
    width: 200px;
    height: 200px;
  }
}
.head-title{
  margin-bottom: 20px;
  margin-left: -10px;
}
.rowCsbsTypes{
  background: #f1f1f1;
}
.login-head{
  //box-shadow: 0 2px 9px rgba(0,25,59,.1);
}
.login-footer{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 75px;
  line-height: 75px;
  text-align: center;
  color: #999;
  font-size: 14px;
}
.login-form{
  .login-title{
    padding-top: 32px;
    padding-bottom: 40px;
    margin-bottom: 46px;
    line-height: 1em;
    color: #666;
    font-size: 24px;
    background: url("./images/login/loginTitle.png") no-repeat bottom center;
  }
  .login-form{
    width: 326px;
    margin: 0 auto;
    input{
      height: 42px;
      border-radius: 21px;
      border: 1px solid #ddd;
      background: transparent;
      //color: #ccc!important;
      &:-webkit-autofill {
        //-webkit-box-shadow: 0 0 0px 1000px white inset;
        //-webkit-text-fill-color: transparent;
      }
    }
    .login-form-button{
      width: 100%;
      height: 42px;
      border-radius: 21px;
      margin-top: 20px;
    }
  }
  .login-form-msg{
    padding-top: 14px;
    text-align: center;
    color: #0084ff;
    font-size: 14px;
    span{
      display: inline-block;
      padding-left: 16px;
      background: url("./images/login/loginMsg.png") no-repeat center left;
    }
  }
}

.order-request-content{
  padding-bottom: 300px;
  .imgList{
    img{
      max-width: 100px;
      margin-right: 10px;
    }
  }
  .confirm{
    border-top: 1px solid #eee;
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 20px;
  }
}

.redRow{
  //background: red;
  color: red;
  //transition: all 0s;
}
.greenRow{
  color: #25c67a;
}
//.redRow:hover{
//  color: rgba(0, 0, 0, 0.65);
//}
