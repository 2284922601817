.menu-left{
  width: 240px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  .munu-list{
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: rgba(5,213,251,.2);
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: rgba(5,213,251,.2);
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
    }
    &::-webkit-scrollbar-track:hover {
      background-color: rgba(5,213,251,.3);
      -webkit-box-shadow: inset 0 0 6px rgba(5,213,251,.4);
    }
    &::-webkit-scrollbar-track:active {
      background-color: rgba(5,213,251,.5);
      -webkit-box-shadow: inset 0 0 6px rgba(5,213,251,.1);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(5,213,251,.3);
      -webkit-box-shadow: inset 1px 1px 0 rgba(0,0,0,.1);
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(5,213,251,.6);
      -webkit-box-shadow: inset 1px 1px 0 rgba(0,0,0,.1);
    }
    &::-webkit-scrollbar-thumb:active {
      background: rgba(5,213,251,.8);
    }
  }
  .menu-head{
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #01a2ff;
    img{
      width: 119px;
      height: 43px;
    }
  }
  .ant-menu.ant-menu-dark .ant-menu-item-selected{

  }
}
