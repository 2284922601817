.talkTitle {
  color: blue;
  cursor: pointer;
}

.cardTalk {
  padding: 20px;
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
  // min-height: 60px;
  // max-height: 300px;
  // line-height: 60px;
  // overflow-y: scroll;
  border: 1px rgb(247, 237, 237) solid;
  .talkItem {
    // width: 100%;
    min-height: 60px;
    padding: 15px 15px;
    margin: 0;
    background-color: rgb(253, 250, 250);
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    // flex-direction: column;
    // justify-content: start;
    .talkItemTime {
      height: 30px;
      width: 100%;
      // text-align: center;
      line-height: 30px;
    }
    .talkItemContent {
      min-height: 30px;
      width: 100%;
      line-height: 1.3;
    }
  }
}
