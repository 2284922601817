.APP{
  height: 100%;
  display: flex;
}
.AppContainer {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  &.rolo1{
    margin-left: 240px;
  }
  .ContentContainer {
    flex: 1;
    position: relative;
  }
}

.listRadioTab{
  padding: 0 20px 20px;
}
.listQuerydata{
  padding-bottom: 20px;
  font-size: 16px;
  .ant-card-body{
    padding: 0;
  }
  .row{
    display: flex;
    flex-wrap: wrap;
    .item{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 140px;
      padding: 20px 0;
      border: 1px solid #eee;
      border-radius: 20px;
      margin: 10px;
      background: #f7f7f7;
      font-size: 14px;
      .label{
        //width: 100px;
        //margin-right: 20px;
      }
      .value{
        //width: 200px;
        text-align: center;
        white-space:normal;
        word-break:break-all;
        .num{
          color: red;
          font-weight: 600;
          //font-size: 18px;
        }
      }
    }
  }
}
.ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content{
  width: auto!important;
}
.showImgsGroup{
  display: inline-block;
  .ant-checkbox-wrapper{
    //width: 84px;
    //position: relative;
    border-radius: 6px;
    margin-right: 10px;
    width: 104px;
    height: 104px;
    padding: 10px;
    border: 1px dashed #d9d9d9;
    &.ant-checkbox-wrapper-checked{
      border: 1px solid #0084ff;
    }
  }
  .ant-checkbox{
    width: 0;
    height: 0;
    overflow: hidden;
  }
  .ant-checkbox + span{
    padding: 0;
  }
  img{
    width: 84px;
    height: 84px;
    object-fit: cover;
  }
}

//.ant-steps-item-content{
//  width: auto !important;
//}

.feedContentBox{
  width: 375px;
  box-sizing: border-box;
  padding: 10px;
  background: #ededed;
  .contentBox{
    margin-top: 10px;
    padding: 10px 0;
    background: #fff;
    border-radius: 10px;
    .title{
      font-size: 16px;
      color: #666;
      //overflow: hidden;
      //text-overflow: ellipsis;
      //white-space: nowrap;
      //padding-bottom: 10px;
      padding: 0 10px 10px;
    }
    .image{
      img, .img{
        //max-width: 120px;
        height: 107px;
        width: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        //border-radius: 10px;
        /* max-height: 200rpx; */
      }
      &.image1{
        img, .img{
          width: 100%;
        }
      }
      &.image2{
        display: flex;
        //flex-wrap: wrap;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 0 10px;
        img, .img{
          width: 107px;
          height: 107px;
          margin-bottom: 10px;
          border-radius: 5px;
        }
      }
    }
  }
}

.classList-conent{
  .ant-form-item{
    margin-bottom: 0;
  }
}


.user-manage .ant-form-item-label{
  min-width: 104px;
}
.ant-form-item-label{
  /*min-width: 104px;*/
  /*display: inline-block;*/
}
.qrcode{
  max-width:150px;
}
.qrcode canvas{
  max-width:100%;
  height:auto!important;
}
.optbtn a{
  padding:0 4px;
}
.optbtn button{
  margin: 4px;
}
.user-conl{
  border-right:1px dashed #e8e8e8;
  text-align: center;
  padding: 0 10px;
}
.user-conl .head{
  margin-bottom: 10px;
}
.user-conl .ant-avatar-circle{
  margin-bottom: 10px;
}
.user-conl .head .qrcode{
  width: 64px;
  margin: 0 auto;
}
.user-conl .user-name{
  margin-bottom: 4px;
  color: rgba(0,0,0,.85);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.user-conl .user-context{
  padding-left:12px;
  margin-bottom: 10px;
  text-align: left;
}
.user-conl .user-context p{
  margin-bottom: 0;
}
.user-conl .user-tag{
  text-align: left;
}
.user-conl .user-tag .user-tag-title{
  margin-bottom: 6px;
}
.user-conl .user-tag .ant-tag{
  margin-bottom: 8px;
}
.user-conl .user-context p{
  word-break:break-all
}
.avatar-box{
  width:64px;
  margin:0 auto;
  position: relative;
}
.avatar-box .ant-avatar.ant-avatar-icon {
  margin-bottom: 0;
}
.avatar-box i.type{
  position: absolute;
  color:green;
  top:0;
}
.avatar-box i.team{
  position: absolute;
  color:rgb(45, 183, 245);
  top:0;
  left:-14px;
}
.user-name i{
  font-size:14px;
  margin:0 4px;
}
.user-name i.type{
  color:green;
}
.user-name i.team{
  color:rgb(45, 183, 245);
}
.userdetall-tabs{
  display:flex;
  align-items: center;
  height: 100px;
  background: #eee;
  margin: 20px;
  border-radius: 8px;
  padding:0 20px;
  justify-content:space-around;
}
.userdetall-tabs .tabs-list {
  color: #000;
  text-align:center;
  padding: 20px;
  font-weight: 600;
}
.userdetall-tabs .tabs-list p{
  margin-bottom:0;
}
.userdetall-tabs .tabs-list .title{
  font-size: 20px
}
.userdetall-tabs .tabs-list .num{
  font-size: 18px
}

.userdetall-tabs .ant-divider-vertical{
  height: 80px;
  margin: 10px;
  border-color: #cacaca;
}
.userdetail-btns{
  padding: 10px;
}
.userdetail-btns button{
  font-size:18px;
  margin:0 6px;
}

